<template>
<div>
  <div class="card-box mt-10" v-if="task.status === 3">
    <van-divider content-position="center">扫码核销后继续以下操作</van-divider>
    <div class="txt-c">
      <van-button type="success" @click="clickSend">发送到群</van-button> &nbsp;&nbsp;
      <van-button type="warning" @click="clickRefuse">无法核销</van-button> &nbsp;&nbsp;
      <van-button type="primary" @click="clickConfirm">我已核销</van-button>
    </div>

    <van-divider content-position="center">核销码</van-divider>
    <div class="txt-c">
      <p>{{data.code}}</p>
      <van-image
          width="95%"
          fit="contain"
          position="center"
          :src="data.image"
      />
    </div>
  </div>

  <div v-else>
    <div class="card-box mt-10">
      <van-divider content-position="center">提示</van-divider>

      <div class="txt-c">
        <div v-show="task.status === 2">
          <p>本次核销已完成，核销码冷却中</p>
          <p>下次核销时间：{{ task.next_at }}</p>
        </div>
        <div v-show="task.status === 1">
          <van-empty description="暂时没有可核销的团单" />
        </div>
      </div>
    </div>

    <div class="mt-10">
      <div class="card-box">
        <van-cell-group inset>
          <van-cell clickable :title="'点击' + (subscribe ? '关闭' : '开启') + '微信通知'" @click="createSubscribe()">
            <template #right-icon>
              <van-switch v-model="subscribe" :active-value="1" :inactive-value="0"/>
            </template>
          </van-cell>
        </van-cell-group>
      </div>
      <div class="card-box txt-c mt-10">
        <p>如存在未核销任务，每小时提醒一次</p>
        <p>关注后才可接收通知消息</p>
        <van-button type="primary" size="small" url="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzk0MDUzMTIyMQ==#wechat_redirect">关注公众号</van-button>
      </div>
    </div>
  </div>


  <van-popup v-model:show="confirm.show" round position="bottom">
    <p class="txt-c">请核对信息，或修改成实际信息</p>
    <van-form @submit="submitConfirm">
      <van-cell-group inset>
        <van-field
            v-model="confirm.data.code"
            type="digit"
            name="code"
            label="券码"
            placeholder="输入券码"
            :rules="[{ required: true, message: '请输入券码', 'trigger':'onSubmit'}]"
        />
        <van-field
            v-model="confirm.data.confirm_price"
            type="number"
            name="confirm_price"
            label="金额"
            placeholder="请输入核销的金额"
            :rules="[{ required: true, message: '请输入金额' , 'trigger':'onSubmit'}]"
        />
      </van-cell-group>
      <div style="margin: 16px;">
        <van-button round block type="success" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </van-popup>

  <van-popup v-model:show="refuse.show" position="bottom">
    <p class="txt-c">请选择无法核销的原因</p>
    <van-form @submit="submitRefuse">
      <van-radio-group v-model="refuse.data.fail_text">
        <van-cell-group inset >
          <van-cell v-for="(item, key) in plan" :key="key" :title="item" clickable @click="selectRefuse(item)">
            <template #right-icon>
              <van-radio :name="item" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </van-popup>

  <van-overlay :show="loading">
    <div style="margin-top: 200px;text-align: center;">
      <van-loading type="spinner" /><span style="color:#fff;">加载中...</span>
    </div>
  </van-overlay>
</div>
</template>


<script>
  import {apiGetMerchantTaskSale, apiConfirmMerchantTaskSale, apiSendWechatMessage} from '@/api/taskApi'
  import {apiGetSubscribe, apiStoreSubscribe} from '@/api/myApi'
  import {showNotify} from 'vant'
  export default {
    created () {
      this.id = this.subscribeForm.ext_id = this.$route.params.id
      this.password = this.$route.params.password

      if (!(this.id && this.password)) {
        this.$router.replace({
          name: 'index'
        })
        return
      }
      this.getSubscribe()
      this.getSale()
    },
    data () {
      return {
        id: '',
        password: '',
        task:{
          // 1，没有核销任务，
          // 2，有核销任务但是还没到时间，
          // 3，可以核销
          status: 1,
        },
        data: {},
        confirm: {
          show: false,
          data: {},
        },
        subscribe: '',
        subscribeForm: {
          ext_id: 0,
          type: 'merchant.task.sale'
        },
        refuse: {
          show: false,
          data: {
            fail_text: '',
          },
        },
        plan: [
            '不是本店的核销码',
            '订单已经退款',
            '二维码无法识别',
            '其他异常',
        ],
        loading: false,
      }
    },
    methods: {
      getSale() {
        this.loading = true
        apiGetMerchantTaskSale(this.id, this.password).then((res) =>{
          this.data = res.sale
          this.task = res.task
          this.loading = false
        })
      },
      clickSend () {
        apiSendWechatMessage({
          sale_id: this.data.id,
          task_id: this.task.id,
          password: this.password,
        }).then(() =>{
          showNotify({
            type: "warning",
            message: "发送到群成功",
          })
        })
      },
      getSubscribe () {
        apiGetSubscribe(this.subscribeForm).then((res) =>{
          this.subscribe = parseInt(res.subscribe)
        })
      },
      createSubscribe() {
        apiStoreSubscribe(this.subscribeForm).then((res) =>{
          showNotify({type: 'primary', message:'操作成功'})
          this.subscribe = parseInt(res.subscribe)
        })
      },
      clickRefuse () {
        this.refuse.data.code = this.data.code
        this.refuse.data.password = this.password
        this.refuse.data.verify = 0
        this.refuse.data.fail_text = this.plan[0]
        this.refuse.data.record_id = this.data.id

        this.refuse.show = true
      },
      clickConfirm () {
        this.confirm.data.confirm_price = this.data.price
        this.confirm.data.code = this.data.code
        this.confirm.data.password = this.password
        this.confirm.data.verify = 1
        this.confirm.data.record_id = this.data.id

        this.confirm.show = true
      },
      selectRefuse (item) {
        this.refuse.data.fail_text = item
      },
      submitRefuse () {
        apiConfirmMerchantTaskSale(this.id, this.refuse.data).then(() =>{
          showNotify({
            type: 'success',
            message: '反馈成功'
          })

          this.refuse.data = {}
          this.refuse.show = false

          this.getSale()
        })
      },
      submitConfirm () {
        apiConfirmMerchantTaskSale(this.id, this.confirm.data).then(() =>{
          showNotify({
            type: 'success',
            message: '提交成功',
            duration: 10000
          })

          this.confirm.data = {}
          this.confirm.show = false

          this.getSale()
        })
      }
    }
  }
</script>
